:root {
  --darkGreen: #146356;
  --lightGreen: #dbec8e;
  --yellow: #fff1bd;
  --orange: #f3c892;
  --dark: #1e2229;
  --grey: #e5e5e5;
  --white: #fff;
}

/* Fonts
   ========================================================================== */

/* Global Styles
   ========================================================================== */

h1 {
  font-family: sans-serif;
}
h2 {
  font-family: sans-serif;
}
h3 {
  font-family: sans-serif;
}
p {
  font-family: sans-serif;
}

iframe {
  width: 100% !important;
}

a:hover {
  text-decoration: none;
  color: var(--lightGreen);
}

a {
  color: var(--darkGreen);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: sans-serif;
  z-index: -100;
}

body {
  background-color: var(--grey);
  color: var(--dark);
}

footer {
  background-color: var(--lightGreen);
  min-width: 100%;
}

#root {
  min-height: 100%;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.smallText {
  font-size: 1rem;
  font-family: sans-serif;
  line-height: 0;
}

.flexWrapper {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.flexGrow {
  flex-grow: 10;
}

/* Backgrounds & containers
   ========================================================================== */

.background {
  /* background-image: url("/public/assets/images/grid.png"); */
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 12vh;
  position: relative;
  top: 0;
  z-index: 10;
}

.backgroundAbsolute {
  /* background-image: url("/public/assets/images/grid.png"); */
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 12vh;
  position: absolute;
  top: 0;
  z-index: 10;
}

.photoBackgroundOverlay {
  background-color: #000000;
  opacity: 70%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -15;
}

.photoBackground {
  /* background-image: url("/public/assets/images/canada.jpg"); */
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
}

.photoBackgroundContainer {
  height: 100%;
}

.photoHeaderContainer {
  height: 40vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: -10;
}

.photoHeaderImg {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -5;
}

.photoHeaderOverlay {
  background-color: #000000;
  opacity: 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
}

.photoHeaderText {
  position: relative;
  z-index: 10;
}

.accordianContainer {
  margin: 27px;
}

.textUnderlay {
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
}

/* Buttons
   ========================================================================== */

.backButton {
  bottom: 4vh;
  left: 24px;
  z-index: 100;
}

.forwardButton {
  bottom: 4vh;
  right: 24px;
  z-index: 100;
}

.searchButton {
  bottom: 5%;
  right: 5%;
  z-index: 100;
}

.roundButton {
  width: 50px;
  height: 50px;
}

.textRoundButton {
  height: 50px;
}

.textSizeButtonDecrease {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -204px;
  left: -102px;
}

.textSizeButtonIncrease {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -204px;
  left: -48px;
}

.photoButtonContainer {
  height: 100px;
  width: 100%;
  position: relative;
  border-radius: 80px;
}

.photoButtonImg {
  position: absolute;
  object-fit: cover;
  border-radius: 80px;
  height: 100px;
  width: 100%;
}

.photoOverlay {
  background-color: #000000;
  opacity: 60%;
  width: 100%;
  height: 100px;
  position: absolute;
  border-radius: 80px;
  top: 0;
  z-index: 5;
}

.photoButtonText {
  position: relative;
  z-index: 10;
}

.smallPhotoButtonText {
  position: relative;
  z-index: 10;
}

@media only screen and (max-width: 500px) {
  .smallPhotoButtonText {
    display: none;
  }
}

.photoOverlay:hover {
  background-color: var(--lightGreen);
  opacity: 100%;
  cursor: pointer;
}

.photoButtonText:hover + .photoOverlay {
  background-color: var(--lightGreen);
  opacity: 100%;
  cursor: pointer;
}

.photoButtonText:hover {
  cursor: pointer;
}

.changeLang {
  position: fixed;
  right: 40px;
  top: 5vh;
  z-index: 100;
}

.attributionLink {
  color: var(--white);
}

.attributionLinkDark {
  color: var(--dark);
}

.attributionLink > a {
  color: var(--white);
  text-decoration: underline;
  margin: 0 3px;
}

.attributionLinkDark > a {
  color: var(--dark);
  text-decoration: underline;
  margin: 0 3px;
}

.MuiTooltip-tooltip {
  font-family: 'Montserrat alternates';
  font-size: 1rem;
}

/* Menus
   ========================================================================== */

.socials {
  height: 10vh;
  bottom: 0;
  position: relative;
}

.langMenu {
  position: absolute;
  bottom: -150px;
  left: -100px;
}

.langMenuItem {
  width: 100%;
  height: 100%;
}

.langMenuItemDisabled {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.4);
}

.langMenuItemWrapper:first-child {
  border-radius: inherit;
  padding: 24px;
  margin: 0;
}

.langMenuItemWrapper {
  border-radius: inherit;
  padding: 24px;
  margin-top: -12px;
}

.langMenuItemWrapper:hover {
  background-color: var(--yellow);
  cursor: pointer;
}

.langMenuItemWrapper:hover .langMenuItem {
  color: var(--indigo);
  cursor: pointer;
}

.langMenuItemWrapperDisabled:first-child {
  border-radius: inherit;
  padding: 24px;
  margin: 0;
}

.langMenuItemWrapperDisabled {
  border-radius: inherit;
  padding: 24px;
  margin-top: -12px;
}

.langMenuItemWrapperDisabled:hover {
  background-color: var(--white);
}

.langMenuItemWrapperDisabled:hover .langMenuItem {
  cursor: default;
}

/* Loading
   ========================================================================== */

.loadingText {
  margin-bottom: 10px;
  text-align: center;
}

.dots-bars-5 {
  text-align: center;
  width: 40px;
  height: 30px;
  --c: linear-gradient(currentColor 0 0);
  background: var(--c) 0 100%/8px 30px, var(--c) 50% 100%/8px 20px,
    var(--c) 100% 100%/8px 10px;
  background-repeat: no-repeat;
  position: relative;
  clip-path: inset(-100% 0);
}
.dots-bars-5:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  left: -16px;
  top: 0;
  animation: db5-1 2s linear infinite,
    db5-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

@keyframes db5-1 {
  0% {
    left: -16px;
    transform: translateY(-8px);
  }
  100% {
    left: calc(100% + 8px);
    transform: translateY(22px);
  }
}

@keyframes db5-2 {
  100% {
    top: -0.1px;
  }
}

/* Material UI overrides where I couldn't figure out a better way to do it
   ========================================================================== */

/* for overriding when label is blank*/
.notranslate {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Algolia overrides
   ========================================================================== */

.ais-SearchBox {
  margin: 1em 0;
}
.ais-Pagination {
  margin-top: 1em;
}
.left-panel {
  float: left;
  width: 250px;
}
.right-panel {
  margin-left: 260px;
}
.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}
.ais-Hits-item {
  margin-bottom: 1em;
  width: calc(50% - 1rem);
}
.ais-Hits-item img {
  margin-right: 1em;
}
.hit-name {
  margin-bottom: 0.5em;
}
.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: '';
  position: absolute;
  border-radius: 15px;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 600px) {
  .collectionButton {
    width: 100%;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
